/**
 * @fileoveriew Entrypoint for pages w/ forms.
 */

import $ from 'jquery';

$(document).ready(function () {
    var lang = {
        de: {
            PLEASE_CHOOSE: 'Bitte wählen',
            CHOOSE: 'auswählen',
            CHOOSE_ALL: 'Alle auswählen',
            CHOSEN: 'ausgewählt',
            ALL: 'Alle',
            SEARCH: 'Suche',
            NO_RESULTS_FOR: 'Keine Ergebnisse für ',
            OK: 'OK',
            CANCEL: 'Abbrechen',
        },
        en: {
            PLEASE_CHOOSE: 'Please choose',
            CHOOSE: 'choose',
            CHOOSE_ALL: 'Choose all',
            CHOSEN: 'chosen',
            ALL: 'All',
            SEARCH: 'Search',
            NO_RESULTS_FOR: 'No results for ',
            OK: 'OK',
            CANCEL: 'Cancel',
        },
    };

    lang = lang[$('html').attr('lang').substring(0, 2) || 'de'];

    $('select.js-sumo-select').each(function () {
        const sumoInstance = $(this);
        const parentForm = sumoInstance[0].form;

        // Ensure SumoSelect’s "No results" notice
        // gets cleared after resetting the form.
        parentForm.addEventListener('reset', () => {
            setTimeout(() => {
                sumoInstance[0].sumo.reload();
            }, 500);
        });

        sumoInstance.SumoSelect({
            captionFormat: '{0} ' + lang['CHOSEN'],
            captionFormatAllSelected:
                $(this).data('choose-all') || lang['CHOOSE_ALL'],
            floatWidth: 0,
            forceCustomRendering: false,
            noMatch: lang['NO_RESULTS_FOR'] + ' "{0}"',
            placeholder: $(this).data('placeholder') || lang['PLEASE_CHOOSE'],
            search: $(this).data('search'),
            searchText: lang['SEARCH'],
            selectAll: $(this).data('select-all'),
            triggerChangeCombined: false,
            locale: [
                lang['OK'],
                lang['CANCEL'],
                $(this).data('choose-all') || lang['CHOOSE_ALL'],
            ],
            nativeOnDevice: false,
        });
    });

    /*
     *
     * Select Submit On Change
     *
     * The option value is a key like in normal forms
     *
     */
    $('select.js-submit-onchange').on('change', function () {
        $(this).parents('form').submit();
    });

    /*
     *
     * Select Pageload On Change
     *
     * The option value is a url link
     *
     */
    $('select.js-pageload-onchange').on('change', function () {
        jumpMenu('parent', this, 0);
    });

    /*
     *
     * Disable Submit button after successfully validation
     * and show a spinner icon instead of the button text/icon
     */
    var formSubmitElem = document.querySelector(
        '[data-js-selector="form-submit"]'
    );

    if ($('form[data-validate]').length > 0) {
        $('form[data-validate]')
            .parsley()
            .on('form:validate', function (formInstance) {
                if (formInstance.isValid()) {
                    //
                    formSubmitElem.removeAttribute('type');
                    formSubmitElem.setAttribute('disabled', 'disabled');
                    formSubmitElem.classList.add('c-button--loading');
                }
            });
    }

    $('select.js-sumo-select').on('change', function () {
        if (
            $('#' + $(this).attr('id'))
                .parent()
                .parent()
                .hasClass('parsley-error')
        ) {
            $('form[data-validate]').parsley().validate();
        }
    });

    if (
        $('.c-persons-detail--fact-list') &&
        $(
            '.c-facts-list',
            $('.c-persons-detail--fact-list')
                .next()
                .find('div.o-container:first-child')
        ).length > 0
    ) {
        $('.c-persons-detail--fact-list')
            .removeClass('u-margin-bottom-10')
            .css('margin-bottom', '-1px');
    }

    // Text expanded
    $('[data-read-more-text]').on('click', function () {
        $(this).parent().hide();
        $(this).parent().next().show();
    });

    $('[data-read-less-text]').on('click', function () {
        $(this).parent().parent().hide();
        $(this).parent().parent().prev().show();
    });

    // Show Text
    $('[data-show-more-text]').on('click', function () {
        $(this).parent().addClass('c-teaser__authors--open');
    });

    $('[data-show-less-text]').on('click', function () {
        $(this).parent().removeClass('c-teaser__authors--open');
    });

    $('.c-teaser__authors-text p').each(function () {
        if ($(this).height() <= 28) {
            $(this).parent().parent().find('.c-button').hide();
        }
    });

    updateLinkListColumnMinHeight();
    updateTeaserHeadlineMinHeight();
});

// Same Height LinkList Columns
var linkListColumnMinHeight = 0;
var thislinkListColumnMinHeight = 0;

function updateLinkListColumnMinHeight() {
    $('.c-linklist-column').each(function () {
        linkListColumnMinHeight = 0;
        thislinkListColumnMinHeight = 0;

        $('.c-linklist-column__item', this).each(function () {
            thislinkListColumnMinHeight = $(
                '.c-linklist-column__text-inner',
                this
            ).height();

            if (thislinkListColumnMinHeight > linkListColumnMinHeight) {
                linkListColumnMinHeight = thislinkListColumnMinHeight;
            }
        });

        $('.c-linklist-column__text', this).height(linkListColumnMinHeight);
    });

	const autoSuggests = $('.tx-solr-suggest');


    autoSuggests.each(function(){
		const $autoInstance = $(this).autocomplete()
		$autoInstance.el.addClass('c-autosuggest__input');

		$autoInstance.options.minChars = 3;
        $autoInstance.classes.suggestion = 'autocomplete-suggestion c-autosuggest__suggestion'
		$autoInstance.suggestionsContainer.classList.add('c-autosuggest');
		$autoInstance.suggestionsContainer.style.width = $autoInstance.element.getBoundingClientRect().width + 'px';
        //appendTo search input
		$autoInstance.options.appendTo = $autoInstance.el;
		//console.log($autoInstance)
	})
}

// Same Height PersonTeaser H4
var teaserHeadlineMinHeight = 0;
var thisTeaserHeadlineMinHeight = 0;

function updateTeaserHeadlineMinHeight() {
    $('.c-teaser-set').each(function () {
        teaserHeadlineMinHeight = 0;
        thisTeaserHeadlineMinHeight = 0;

        $('.c-teaser', this).each(function () {
            thisTeaserHeadlineMinHeight = $(
                '.c-teaser__subheadline-inner',
                this
            ).height();

            if (thisTeaserHeadlineMinHeight > teaserHeadlineMinHeight) {
                teaserHeadlineMinHeight = thisTeaserHeadlineMinHeight;
            }
        });

        if (teaserHeadlineMinHeight) {
            $('.c-teaser', this).each(function () {
                if (!$('.c-teaser__subheadline', this).length) {
                    $(this).prepend(
                        '<div class="c-teaser__subheadline u-padding-x-0 u-background-color-secondary"></div>'
                    );
                }
            });
        }
        $('.c-teaser__subheadline', this).height(teaserHeadlineMinHeight + 16);
    });


}

$(window).on('resize', function () {
    updateLinkListColumnMinHeight();
    updateTeaserHeadlineMinHeight();
});

//$(window).resize(updateTeaserHeadlineMinHeight());

function jumpMenu(targ, selObj, restore) {
    window.location =
        '//' +
        window.location.hostname +
        selObj.options[selObj.selectedIndex].value;
    if (restore) selObj.selectedIndex = 0;
}


// const autoSuggests = document.querySelectorAll('.tx-solr-suggest');
// for (const autoSuggest of autoSuggests){
//     const $autoInstance = autoSuggest.autocomplete()
//     if (!$autoInstance) continue;
//     $autoInstance.el.addClass('c-autosuggest__input');
//     $autoInstance.options.minChars = 4;
//     $autoInstance.options.classes.suggestion = 'autocomplete-suggestion c-autosuggest__suggestion'
//     $autoInstance.suggestionsContainer.classList.add('c-autosuggest')
// }
